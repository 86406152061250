.ui_title--primary,
.ui_title--primary_specific {
    padding: 0.8rem;
    color: #555555;
    font-size: xx-large;
    font-family: 'Open Sans', sans-serif
}

#page {
    min-height: 100%;
    position: relative;
}

.ui_header {
    background-color: #172028;
    color: #dddddd;
    height: 75px;
    font-size: 17px;
    font-family: ‘Open Sans’, sans-serif;
    /* position: fixed; */
    width: 100%;
    z-index: 100;
}

#ui_header_specific {
    border-top: 3px solid #FFC600;
}

.ui_header .ui_header__active_area {
    padding: 12px 60px;
    margin: 0 auto;
}

.ui_header .ui_header__active_area .ui_header__sidenav_option {
    float: left;
    padding-right: 40px;
    display: none;
}

.ui_header .ui_header__active_area .fa {
    font-size: 20px;
}

.ui_header .ui_header__active_area .ui_header__part .fa {
    line-height: 48px;
}


.is-active .dropdown-menu {
    display: block;
}

.regperiods {
    width: 810px;
}

thead {
    color: #888888;
}

#header_gotoservice {
    display: none;
}

.footer {
    color: #999999 !important;
}

#banner_text_servicename {
    color: #FFC600;
    font-size: 28px;
}

#banner_text_auth {
    color: #172028;
    font-size: 28px;
}

#banner_login_button_specific {
    background: #FFC600 !important;
    padding-top: 50px !important;
    border-top: 1px solid #CCCCCC !important;
    height: 79px !important;
    box-shadow: inset 0 0 3px #999999;
    -moz-box-shadow: inset 0 0 3px #999999;
    -webkit-box-shadow: inset 0 0 3px #999999;
}

#banner_login_button_specific:hover {
    background: #FFD000 !important;
}

#banner_login_button_specific:active {
    box-shadow: inset 0 0 9px #777777;
    -moz-box-shadow: inset 0 0 9px #777777;
    -webkit-box-shadow: inset 0 0 9px #777777;
}

#banner_login_button_text_specific {
    font-size: 20px !important;
}

.content_options {
    width: 510px;
}

#banner_box_left {
    width: 99%;
}

#header {
    border-top: 3px solid #FFC600;
}

#header_logo {
    background-image: url('../assets/it-logo-100x100.png');
    background-repeat: no-repeat;
    line-height: 44px;
}

table th {
    font-weight: bold;
}

table td,
table th {
    padding: 9px 10px;
    text-align: left;
}

/* Mobile */
@media only screen and (max-width: 767px) {

    table.responsive {
        margin-bottom: 0;
    }

    div.table-wrapper {
        position: relative;
        margin-bottom: 20px;
        overflow: hidden;
        border-right: 1px solid #ccc;
    }

    div.table-wrapper div.scrollable table {
        margin-left: 35%;
    }

    div.table-wrapper div.scrollable {
        overflow: scroll;
        overflow-y: hidden;
    }

    table.responsive td,
    table.responsive th {
        position: relative;
        white-space: nowrap;
        overflow: hidden;
    }

    table.responsive th:first-child,
    table.responsive td:first-child,
    table.responsive td:first-child,
    table.responsive.pinned td {
        /* display: none; */
    }
}

a {
    text-decoration: none !important;
}

.ui--backgroundcolor_of_service {
    background: #FFC600 !important;
    -webkit-transition: all 150ms ease-in-out;
    -moz-transition: all 150ms ease-in-out;
    -o-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
}

.ui--backgroundcolor_of_service:hover {
    background: #FFD000 !important;
    -webkit-transition: all 150ms ease-in-out;
    -moz-transition: all 150ms ease-in-out;
    -o-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
}

.ui--textcolor_of_service {
    color: #FFC600 !important;
    -webkit-transition: all 150ms ease-in-out;
    -moz-transition: all 150ms ease-in-out;
    -o-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
}

#ui_header__logo_specific {
    background-image: url("../assets/it-logo-100x100.png");
    background-size: 36px 36px;
    background-repeat: no-repeat;
}

.ui_header__menu_options_dropdown {
    display: none;
}

.ui_header__option_specific:hover {
    color: #FFC600 !important;
}

.ui_header__option_specific a:hover {
    color: #FFC600 !important;
}

.ui_header__option_specific.ui_dropdown--open {
    color: #FFC600;
}

#ui_banner__text--servicename_specific {
    color: #FFC600;
}

#ui_header__login_options_specific {
    display: none;
}

#ui_header__login_options_specific * {
    display: none !important;
}

/* -------------------------------------------- */
/* Responsivness Levels: 940, 700, 520, 420, 320*/
@media screen and (max-width: 940px) {
    #ui_banner__text--servicetitle_specific {
        display: none;
        -webkit-transition: all 150ms ease-in-out;
        -moz-transition: all 150ms ease-in-out;
        -o-transition: all 150ms ease-in-out;
        transition: all 150ms ease-in-out;
    }
}

@media screen and (max-width: 700px) {
    #ui_banner__text--auth_specific {
        display: none;
        -webkit-transition: all 150ms ease-in-out;
        -moz-transition: all 150ms ease-in-out;
        -o-transition: all 150ms ease-in-out;
        transition: all 150ms ease-in-out;
    }
}

@media screen and (max-width: 520px) {
    #ui_banner__text--servicename_specific {
        display: inline;
        -webkit-transition: all 150ms ease-in-out;
        -moz-transition: all 150ms ease-in-out;
        -o-transition: all 150ms ease-in-out;
        transition: all 150ms ease-in-out;
    }

    #ui_banner__text--auth_specific {
        display: inline;
        -webkit-transition: all 150ms ease-in-out;
        -moz-transition: all 150ms ease-in-out;
        -o-transition: all 150ms ease-in-out;
        transition: all 150ms ease-in-out;
    }

    #ui_banner__logo_specific {
        width: 4vw;
    }

    #ui_banner__box--title_specific {
        width: 100%;
    }

    .ui_header__login_options_username {
        display: none !important;
    }

    .ui_header__login_options_username_icon {
        display: inline !important;
    }
}

@media screen and (max-width: 420px) {
    #ui_banner__text--auth_specific {
        display: none;
        -webkit-transition: all 150ms ease-in-out;
        -moz-transition: all 150ms ease-in-out;
        -o-transition: all 150ms ease-in-out;
        transition: all 150ms ease-in-out;
    }
}

@media screen and (max-width: 320px) {
    #ui_banner__text--servicename_specific {
        top: 25px;
        -webkit-transition: all 150ms ease-in-out;
        -moz-transition: all 150ms ease-in-out;
        -o-transition: all 150ms ease-in-out;
        transition: all 150ms ease-in-out;
    }

    #ui_banner__box--title_specific {
        height: 160px;
        -webkit-transition: all 150ms ease-in-out;
        -moz-transition: all 150ms ease-in-out;
        -o-transition: all 150ms ease-in-out;
        transition: all 150ms ease-in-out;
    }
}

.ui_text--size_default {
    font-size: 14px !important;
}

.ui_text--size_XS {
    font-size: 10px !important;
}

.ui_text--size_S {
    font-size: 12px !important;
}

.ui_text--size_L {
    font-size: 18px !important;
}

.ui_text--size_XL {
    font-size: 20px !important;
}

.ui_text--size_XXL {
    font-size: 28px !important;
}

.ui_text--contrast {
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
}

.ui_text--color_dimmed {
    color: rgba(0, 0, 0, 0.2) !important;
    text-shadow: none;
}

.ui--no_padding {
    padding: 0px !important;
}

.ui--padding_top {
    padding-top: 20px !important;
}

.ui--clear_float {
    clear: both;
}

.ui--margin_top_2x {
    margin-top: calc 40px;
}

.ui--margin_top_3x {
    margin-top: calc 60px;
}

.ui--margin_top_4x {
    margin-top: calc 80px;
}

.ui--margin_bottom_1x {
    margin-bottom: 20px;
}

.ui--margin_bottom_2x {
    margin-bottom: calc 40px;
}

.ui--margin_bottom_3x {
    margin-bottom: calc 60px;
}

.ui--margin_bottom_4x {
    margin-bottom: calc 80px;
}

body {
    color: #333333;
    background-color: #fbfbfb;
    text-align: center !important;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif !important;
    margin: 0;
    padding: 0;
}



article,
aside,
figure,
footer,
header,
hgroup,
section {
    border: 0 none;
    font: inherit;
    outline: 0 none;
    display: block;
    margin: 0;
    padding: 0;
}

a {
    color: #006375;
    text-decoration: none;
    -webkit-transition: all 150ms ease-in-out;
    -moz-transition: all 150ms ease-in-out;
    -o-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
}

a:hover {
    text-decoration: underline;
    -webkit-transition: all 150ms ease-in-out;
    -moz-transition: all 150ms ease-in-out;
    -o-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
}

img {
    border-style: none;
}

* {
    outline: none;
}

.ui_button {
    border-radius: 2px;
    box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.5);
    background: #1d9eb2;
    font-family: ‘Open Sans’, sans-serif;
    font-size: 14px;
    color: #FFFFFF !important;
    height: 30px;
    padding: 7px 15px;
    box-sizing: border-box;
    cursor: pointer;
    text-decoration: none;
    margin-left: 10px;
    margin-bottom: 10px;
}

.ui_button:hover {
    background: #26B5CC;
}

.ui_horizontal_line {
    width: 100%;
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 0.15) 2%, rgba(0, 0, 0, 0.15) 98%, transparent 100%);
    margin-top: 20px;
}

.ui_header .ui_header__active_area .ui_header__option {
    min-width: 50px;
    float: left;
    transform: translate(0px, 0px);
    cursor: pointer;
}

.ui_header .ui_header__active_area .ui_header__option a {
    color: #dddddd;
}

.ui_header .ui_header__active_area .ui_header__option.ui_dropdown--open {
    background: #24313D;
    color: #333333;
    border-radius: 2px 2px 0px 0px;
    line-height: 22px !important;
    cursor: pointer;
}

.ui_header .ui_header__active_area .ui_header__option--text {
    font-size: 18px !important;
    font-family: ‘Open Sans’, sans-serif;
    font-size: 16px !important;
    padding-left: 15px;
    padding-right: 15px;
}

.ui_header .ui_header__active_area .ui_header__part {
    float: right;
    line-height: 48px;
}

.ui_header .ui_header__active_area .ui_header__part a {
    text-decoration: none;
}

.ui_header .ui_header__active_area .ui_header__title {
    float: left;
    font-family: ‘Open Sans’, sans-serif;
}

.ui_header .ui_header__active_area .ui_header__title a {
    background-repeat: no-repeat;
    background-position: left center;
    display: block;
    padding-left: 50px;
    text-decoration: none;
}

.ui_header .ui_header__active_area .ui_header__title .ui_header__title--partA {
    font-size: 24px;
    font-family: ‘Open Sans’, sans-serif;
    ;
    color: #CCCCCC;
}

.ui_header .ui_header__active_area .ui_header__title .ui_header__title--partB {
    font-size: 24px;
    color: #FFFFFF;
    font-family: ‘Open Sans’, sans-serif;
    ;
}

.ui_header .ui_header__active_area .ui_header__title .ui_header__title--seperator {
    font-size: 17px;
    position: relative;
    bottom: 2px;
    color: #555555;
}

.ui_header .ui_header__active_area .ui_header__title .ui_header__title--partC {
    font-size: 13px;
    color: #CBCBCB;
    font-family: ‘Open Sans’, sans-serif;
    ;
}

.ui_header .ui_header__active_area .ui_header__login_options {
    margin-left: 10px;
}

.ui_header .ui_header__active_area .ui_header__login_options .ui_header__login_options__username {
    font-style: italic !important;
}

.ui_header .ui_header__active_area .ui_header__login_options .ui_header__login_options__username_icon {
    display: none;
}

.ui_header .ui_header__active_area .ui_header__gotoservice_li {
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 100px 10px;
    padding: 10px 50px 10px 0px !important;
    line-height: 25px !important;
}

.ui_header .ui_header__active_area .ui_header__gotoservice_li:hover {
    background-size: 25px 25px !important;
    background-repeat: no-repeat !important;
    background-position: 100px 10px !important;
    -webkit-transition: all 150ms ease-in-out;
    -moz-transition: all 150ms ease-in-out;
    -o-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
}

.ui_header .ui_header__active_area #ui_header__gotoservice_li--itauth {
    background-image: url(../assets/it-logo-100x100.png);
}

.ui_header .ui_header__active_area #ui_header__gotoservice_li--sisauth {
    background-image: url(../assets/sis-128.png);
}

.ui_header .ui_header__active_area #ui_header__gotoservice_li--webmailauth {
    background-image: url(../assets/webmail-35x35.png);
}

.ui_header .ui_header__active_area #ui_header__gotoservice_li--elearningauth {
    background-image: url(../assets/elearning-128.png);
}

.ui_header .ui_header__active_area #ui_header__gotoservice_li--mycloudauth {
    background-image: url(../assets/mycloud-36F.png);
}

.ui_footer {
    margin-top: 4em;
    background: #f4f4f4;
    border-top: 1px solid #c4c4c4;
    box-sizing: border-box;
    color: #696969;
    padding-top: 2.2em;
    bottom: 0;
    height: 75px;
    position: relative;
    width: 100%;
}

.ui_content {
    font-family: 'Open Sans', sans-serif;
    background-color: #fbfbfb;
    /* padding-top: 90px; */
    padding-bottom: 120px;
}

.ui_content__active_area {
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 10px;
    max-width: 960px;
    margin: 0 auto;
}

.ui_banner {
    height: 130px;
    margin: 0 auto;
}

.ui_banner .ui_banner__box {
    margin-top: 20px;
    background: #FFF;
    border-radius: 5px;
    box-shadow: 0 0 25px #CCC;
    text-align: center;
    height: 130px;
    float: left;
    box-sizing: border-box;
}

.ui_banner .ui_banner__box--title {
    padding-left: 30px;
    padding-right: 30px;
    margin-right: 20px;
    width: 600px;
}

.ui_banner .ui_banner__logo {
    width: 4vw;
}

.ui_banner .ui_banner__text--servicename {
    font-size: 32px;
    font-family: ‘Open Sans’, sans-serif;
    color: #FFC600;
    position: relative;
    padding-left: 20px;
}

.ui_banner .ui_banner__text--auth {
    font-size: 32px;
    font-family: ‘Open Sans’, sans-serif;
    color: #555555;
    position: relative;
}

.ui_box {
    margin-top: 20px;
    background: #FFF;
    border-radius: 4px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
    text-align: left;
    box-sizing: border-box;
    font-size: 14px;
    width: 100%;
    padding: 20px;
    color: #555555;
}

.ui_table {
    border: 1px solid #dddddd;
    border-collapse: collapse;
    margin: 0 auto;
    width: 100%;
    display: block;
    overflow-x: auto;
}

.ui_table tr:nth-child(odd) td {
    background: #FFFFFF;
}

.ui_table tr:nth-child(even) td {
    background: #f6f6f6;
}

.ui_table th {
    background: #f0f0f0;
    border-bottom: 1px solid #DDDDDD;
}

@media screen and (max-width: 940px) {
    .ui_header__title--seperator {
        display: none;
    }

    .ui_header__title--partC {
        display: none;
    }
}

@media screen and (max-width: 700px) {
    .ui_header__active_area {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}